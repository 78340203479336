//Imports
import { ready } from './_utils/ready.js';

// Variables
let kinDateElement = document.getElementById('highlandsDate');

ready(() => {
    if (kinDateElement !== null && kinDateElement !== undefined) {
        var kinDate = new Date();
        kinDateElement.textContent = (kinDate.getMonth() + 1) + '.' + kinDate.getDate() + '.' + kinDate.getFullYear().toString().substring(2);

        setTimeout(function () {
            kinDateElement.classList.add('show-visible');
        }, 1000);
    }
});
